import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We aggregated some of the most successful ways to build credit. Before reading this article, you should `}<a parentName="p" {...{
        "href": "https://credit101.net/score/four-ways-to-check-your-credit-score/"
      }}>{`check your credit score`}</a>{` so that we can give you credit-building plans tailored to your current credit standing.`}</p>
    <h2>{`No Credit and Poor Credit`}</h2>
    <p><strong parentName="p">{`Best Overall: Become an Authorized User`}</strong></p>
    <p>{`For both scenarios, being added as an authorized user is the fastest and safest way to build credit. On-time payments made by the primary user help you demonstrate responsible use of credit. Reports aggregated by `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#fico"
      }}>{`FICO`}</a>{` shows that becoming an authorized user can `}<strong parentName="p">{`boost your credit score by up to 30 points within 30 days`}</strong>{`. Plus, becoming an authorized user `}<strong parentName="p">{`will not`}</strong>{` result in a hard pull on your credit report and won't hurt your credit score.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <p><strong parentName="p">{`Best for No Credit: Get a Credit Card`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://ubernewsroomapi.10upcdn.com/wp-content/uploads/2017/10/Header-Image-Card-Only-Photo.jpg",
        "alt": "Uber Visa Card"
      }}></img></p>
    <figcaption>Uber Visa Card (Photo by Uber)</figcaption>
    <p>{`Getting a credit card is certainly the best way to begin your credit journey. Getting an entry-level card and using it for small purchases can help you build credit history and earn rewards.`}</p>
    <p>{`Here, we do not explicitly recommend a secured credit card because you can get a non-secured card very easily. We've written an article to help you get your first credit card:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/cards/getting-your-first-credit-card/"
        }}>{`Getting Your First Credit Card`}</a></p>
    </blockquote>
    <p><strong parentName="p">{`Best for Rebuilding: Pay Your Balances > Opening New Account`}</strong></p>
    <p>{`If you already have many credit accounts opened, researches have shown that opening new credit accounts `}<strong parentName="p">{`will not`}</strong>{` help you until you demonstrate responsible management of your existing accounts.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <p><strong parentName="p">{`Best for Poor Credit but New to Credit: Secured Credit Cards`}</strong></p>
    <p>{`If you are new to credit-building, `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/credit-card-glossary/#secured-credit-card"
      }}>{`secured credit cards`}</a>{` are your best bet as you have higher chances of getting approved for those cards. A credit-builder loan is an interesting alternative. Essentially you're paying the bank to report your on-time payment to the credit bureaus. However, we are seeing mixed results.`}</p>
    <h2>{`Fair Credit`}</h2>
    <p>{`It can be frustrating to scramble the last few points to get a good credit score. The author was stuck in the "fair credit" stage for about a year before getting above a 720. But don't worry, we're here to help.`}</p>
    <p><strong parentName="p">{`New Accounts`}</strong></p>
    <p>{`You can get up to 5 hard pulls per year without doing much damage to your credit score. After that, the inquires will remain on your report for another year but will not count against your score. Approved credit applications can increase the number of open accounts and lower your overall utilization. Both of which are key to credit building.`}</p>
    <p>{`There are two added benefits: 1) You can build your average age of accounts in the long term. 2) You'll get all the sign-up bonuses when you open new accounts.`}</p>
    <p>{`Warning: Only apply for the cards you know you'll be approved for because you'll be wasting your opportunities and time otherwise.`}</p>
    <p><strong parentName="p">{`Credit Line Increase`}</strong></p>
    <p>{`If you think opening more accounts is risky, you can wait for issuers to increase your credit limit. Increase in credit limit will positively impact your score as it lowers your utilization and increases the total credit available to you. You can also request lenders to increase your credit line, which will sometimes result in a hard pull.`}</p>
    <h2>{`Good Credit`}</h2>
    <p><img parentName="p" {...{
        "src": "https://live.staticflickr.com/7711/27031853000_4b6627aa89_o_d.jpg",
        "alt": "Good Credit"
      }}></img></p>
    <figcaption>(Photo by CafeCredit)</figcaption>
    <p>{`To improve your credit rating from good to excellent is all about patience and pushing through the last mile.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <p><strong parentName="p">{`Patience`}</strong></p>
    <p>{`It takes time to build trust. Same thing with building credit. 10% of your FICO score is based on the `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary#average-age-of-accounts"
      }}>{`average age of accounts`}</a>{` (AAoA) and the age of your oldest account. Keep up the good work and your credit will go up naturally as your account age.`}</p>
    <p><strong parentName="p">{`Reduce Your Utilization`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#utilization-ratio"
      }}>{`Utilization ratio`}</a>{` makes up about 30% of your FICO score. Generally, a utilization ratio higher than 30% will hurt your score. Credit 101's research indicates that most people with excellent credit have a utilization ratio of less than 10%. Some reports also state that a 1-2% utilization might be better than 0%. The author himself saw a minor 10-point increase in his score when he tried this method.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      